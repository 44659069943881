import React, { useRef } from 'react'
import Footer from './footer'
import GlobalIncludes from './global-includes'
import Hero from './hero'
import TestGrid from './test-grid'
import DesktopMenu from './desktop/menu'
import DesktopStickyPanel from './desktop/sticky-panel'
import MobileCuubLogo from './mobile/cuub-logo'
import MobileMenu from './mobile/menu'
import MobileQuoteButton from './mobile/quote-button'

const Root = ({
	desktopMenuBg, // bg class | auto = default
	desktopMenuColor,
	desktopWordmarkColor, // text class | auto = default
	desktopWordmarkPosition, // hidden | static | sliding = default
	desktopCtaVariant, // hidden | black | white | transparent | auto = default
	mobileMenuBg,
	mobileMenuColor,
	mobileWordmarkColor, // text class | auto = default
	mobileWordmarkPosition, // hidden | static | sliding = default
	mobileCtaVariant, // hidden | black | white | transparent | auto = default
	mainFullWidth,

	children,
	hero,
	hideFooter,
	seoFields,
}) => {
	const expectHero = !!hero
	const heroRef = useRef()
	const mainRef = useRef()

	const relativeProps = {
		expectHero,
		heroRef,
		mainRef,
		transparentBg: hideFooter,
	}

	const menuProps = {
		expectHero,
		bgClass: desktopMenuBg ?? 'auto',
		colorClass: desktopMenuColor ?? 'auto',
	}
	// TODO: probably throw an error if one is auto and the other is not

	const desktopPanelProps = {
		isVisible: !(
			desktopWordmarkPosition === 'hidden' && desktopCtaVariant === 'hidden'
		),
		position: desktopWordmarkPosition === 'static' ? 'absolute' : 'sticky',
		wordmarkColor: desktopWordmarkColor ?? 'auto',
		// wordmarkPosition: desktopWordmarkPosition ?? 'sliding',
		ctaVariant: desktopCtaVariant,
	}

	return (
		<>
			<div
				className={`${
					desktopPanelProps === 'sticky' ? 'lg:pb-screen-step' : ''
				} relative w-full`}
			>
				<Hero hero={hero} ref={heroRef} />
				<DesktopMenu {...menuProps} />
				<MobileMenu {...menuProps} />
				<MobileCuubLogo {...relativeProps} />

				<main
					ref={mainRef}
					className={[
						`relative z-0 min-h-full`,
						desktopPanelProps === 'sticky' ? 'lg:mb-10' : '',
						mainFullWidth ? '' : 'mx-auto max-w-screen-3xl',
					].join(' ')}
				>
					{children}
					<GlobalIncludes incomingSeoFields={seoFields} />
					<TestGrid />
				</main>

				{desktopPanelProps.isVisible ? (
					<DesktopStickyPanel {...relativeProps} {...desktopPanelProps} />
				) : null}
				<MobileQuoteButton {...relativeProps} />
			</div>

			{hideFooter ? '' : <Footer />}
		</>
	)
}

export default Root
