import React, { useRef } from 'react'
import { Link } from 'gatsby'
import Cuub from 'svg/cuub'
import QuoteButton from './quote-button'
import useColorOverHero from 'templates/layout/color-over-hero-hook'

const DesktopStickyPanel = ({
	expectHero,
	heroRef,
	position,
	wordmarkColor,
	ctaVariant,
	isVisible,
}) => {
	const panelRef = useRef()
	const color = useColorOverHero(expectHero, heroRef, panelRef, 0.55)

	const wordmarkColorClass =
		wordmarkColor === 'auto'
			? color === 'white'
				? 'text-white'
				: 'text-black'
			: wordmarkColor

	return (
		<div
			ref={panelRef}
			id="bottom-sticky-panel"
			className={
				position +
				` pointer-events-none bottom-0 z-10 hidden w-full items-center justify-between px-screen-step pb-screen-step lg:flex`
			}
		>
			<div className="pointer-events-auto w-content-step-3">
				<Link to="/" className={wordmarkColorClass + ` accent-hover block`}>
					<Cuub />
				</Link>
			</div>
			{ctaVariant === 'hidden' ? null : (
				<div className="pointer-events-auto h-content-step w-content-step-4 text-[0.8rem]">
					<QuoteButton color={color} />
				</div>
			)}
		</div>
	)
}

export default DesktopStickyPanel
