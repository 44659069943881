import React from 'react'
import { Link } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { Root } from 'templates/layout'
import {
	DesignRow,
	Heading1,
	Heading2,
	Heading4,
	Heading5,
	RegularText,
} from 'components/design'
import LinkWithCube from 'components/link-with-cube'
import OutboundLink from 'components/outbound-link'
import ApplicationForm from './application-form'
import VacanciesList from './list'

const VacancyTpl = ({
	vacancy: { description, jobLinks, title, topFields, hurma_vacancy_id },
	otherVacancies,
}) => (
	<Root
		seoFields={{
			title: `${title} | Careers | CUUB Studio`,
			description: null,
		}}
	>
		<DesignRow
			className="mt-24 md:mt-48 xl:mt-64"
			columnToColumn={
				<>
					<Link to="/careers/" className="accent-hover">
						<Heading4>Careers</Heading4>
					</Link>
					<Heading1 className="mt-2">{title}</Heading1>
					<div className="mt-4 flex flex-col md:flex-row">
						{topFields.map(({ heading, text }, index) => (
							<RegularText
								className={index > 0 ? 'md:ml-12' : ''}
								key={heading}
							>
								<strong>{heading}:</strong> {text}
							</RegularText>
						))}
					</div>
				</>
			}
		/>

		<DesignRow
			className="mt-10 xl:mt-16"
			columnToColumn={
				<div className="publication">
					<PrismicRichText field={description.richText} />
				</div>
			}
		/>

		{jobLinks.length ? (
			<DesignRow
				className="mt-20"
				leftColumn={
					<OutboundLink url={jobLinks[0].url}>
						<Heading5>{jobLinks[0].text}</Heading5>
					</OutboundLink>
				}
				rightColumn={
					jobLinks.length > 1 ? (
						<OutboundLink url={jobLinks[1].url}>
							<Heading5>{jobLinks[1].text}</Heading5>
						</OutboundLink>
					) : null
				}
			/>
		) : null}

		<DesignRow
			className="mt-12 xl:mt-28"
			leftColumn={<Heading2>Apply for this position</Heading2>}
		/>
		<ApplicationForm jobTitle={title} vacancyId={hurma_vacancy_id} />

		{otherVacancies?.totalCount ? (
			<>
				<DesignRow
					className="mt-12 xl:mt-40"
					leftColumn={<Heading2>Other open positions</Heading2>}
				/>
				<VacanciesList vacancies={otherVacancies.list} />

				{otherVacancies.totalCount > 3 ? (
					<DesignRow
						className="mt-10"
						shrinkOnMobile
						leftColumn={
							<div className="ml-4">
								<LinkWithCube
									className="w-full"
									target="/careers/"
									type="link"
								>
									See all open positions
								</LinkWithCube>
							</div>
						}
					/>
				) : null}
			</>
		) : null}
	</Root>
)

export default VacancyTpl
