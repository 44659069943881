import React, { useRef } from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'

import useColorOverHero from 'templates/layout/color-over-hero-hook'

export default function MobileQuoteButton({ expectHero, heroRef }) {
	const buttonRef = useRef()
	const color = useColorOverHero(expectHero, heroRef, buttonRef, 0.55)
	const { pathname } = useLocation()

	return pathname === '/contacts/' ? null : (
		<div
			className="fixed right-screen-step bottom-[calc(0.6*var(--screen-grid-step))] z-10 w-[calc(5.8*var(--screen-grid-step))] max-w-[9rem] lg:static lg:hidden"
			ref={buttonRef}
		>
			<Link
				id="get-a-quote-mobile"
				to="https://research.typeform.com/to/d5SVcDHm"
				className={`group flex h-10 items-center justify-between overflow-hidden px-3 text-xs font-bold uppercase tracking-wider text-white ${
					color === 'black' ? 'bg-black' : ''
				} transition-colors duration-300`}
			>
				<i className={`block h-3 w-3 bg-accent`} />
				Get a Quote
			</Link>
		</div>
	)
}
