import React, { useEffect, useState } from 'react'

export default function TestGrid() {
	const [displayGrid, setDisplayGrid] = useState(false)

	useEffect(() => {
		if (
			window &&
			window.location.hostname.indexOf('cuubstudio') < 0 &&
			window.location.search.toLowerCase().indexOf('grid') > 0
		) {
			setDisplayGrid(true)
		}
	}, [])

	return displayGrid ? (
		<>
			<div className="absolute top-0 left-0 z-50 grid h-full w-full grid-cols-16 lg:hidden">
				<div className="border-r border-pink-300"></div>
				<div className=""></div>
				<div className="border-r border-pink-100"></div>
				<div className=""></div>
				<div className="border-r border-pink-100"></div>
				<div className=""></div>
				<div className="border-r border-pink-300"></div>
				<div className=""></div>
				<div className="border-r border-pink-300"></div>
				<div className=""></div>
				<div className="border-r border-pink-100"></div>
				<div className=""></div>
				<div className="border-r border-pink-100"></div>
				<div className=""></div>
				<div className="border-r border-pink-300"></div>
				<div className=""></div>
			</div>
			<div className="absolute top-0 left-0 z-50 hidden h-full w-full grid-cols-36 lg:grid">
				<div className="border-r border-pink-300"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-300"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-300"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-300"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-300"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-300"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-300"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-100"></div>
				<div className="border-r border-pink-300"></div>
				<div className=""></div>
			</div>
		</>
	) : null
}
