import React from 'react'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

export default function LinkWithCube({
	className,
	cubeAccent,
	children,
	id,
	target,
	type,
}) {
	const wrapperClassName = `relative block overflow-hidden -ml-4 h-16 group text-sm md:text-base ${
		className || ''
	}`
	const inside = [
		<div
			key="first"
			className="pointer-events-none absolute top-0 left-full h-full w-full bg-accent transition-transform duration-300 ease-in group-hover:-translate-x-full group-hover:transform"
		/>,

		<div
			key="second"
			className="pointer-events-none absolute top-0 left-0 flex h-full w-full items-center justify-between pl-4"
		>
			<div className="flex-initial font-semibold leading-tight transition-colors duration-300 group-hover:text-white">
				{children}
			</div>
			<div className="flex h-full w-8 items-center justify-end">
				<i
					className={`block h-3 w-3 ${
						cubeAccent ? 'bg-accent' : 'bg-black'
					} transition-colors duration-100 group-hover:bg-accent`}
				/>
			</div>
		</div>,
	]

	switch (type) {
		case 'link':
			return (
				<Link to={target} className={wrapperClassName}>
					{[...inside]}
				</Link>
			)
		case 'anchor-link':
			return (
				<AnchorLink to={target} className={wrapperClassName}>
					{[...inside]}
				</AnchorLink>
			)
		case 'button':
			return (
				<button onClick={target} className={wrapperClassName} id={id}>
					{[...inside]}
				</button>
			)
		default:
			return null
	}
}
