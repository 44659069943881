import { useEffect, useState } from 'react'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const useColorOverHero = (expectHero, heroRef, currentRef, heightTreshold) => {
	const [color, setColor] = useState(expectHero ? 'white' : 'black')

	useEffect(() => {
		if (
			!expectHero ||
			!currentRef.current ||
			getComputedStyle(currentRef.current).display === 'none'
		) {
			return
		}

		const gridStep = window.innerWidth / 36
		const currentLevel = window.innerHeight - gridStep * (1 + heightTreshold)

		const st = ScrollTrigger.create({
			// markers: true,
			trigger: heroRef.current,
			start: 'top top',
			end: `bottom ${currentLevel}px`,
			onLeave: () => {
				setColor('black')
			},
			onEnterBack: () => {
				setColor('white')
			},
		})

		return () => {
			st && st.kill()
		}
	}, [expectHero, heroRef, currentRef, heightTreshold])

	return color
}

export default useColorOverHero
