import React, { forwardRef } from 'react'
import { DesignRow } from 'components/design'
import { Rimg } from 'components/images'
import BouncingLine from './bouncing-line'
import TestGrid from './test-grid'
import VideoWithCover from 'components/video-with-cover'

const Hero = forwardRef(({ hero }, ref) =>
	hero ? (
		<header className="relative z-0 h-[50vh] w-full lg:h-screen" ref={ref}>
			{hero?.video?.url ? (
				<VideoWithCover
					cover={hero.image?.url ? hero.image : null}
					coverPosition={36}
					coverMobilePosition={16}
					videoUrl={hero.video.url}
				/>
			) : (
				<Rimg
					image={hero.image}
					position={36}
					mobilePosition={16}
					className="h-full w-full object-cover"
				/>
			)}

			<BouncingLine />

			{hero.h1 ? (
				<DesignRow
					className="absolute bottom-screen-step w-full"
					rightColumnWithGutter={
						<h1 className="leading-[1.1] text-white">{hero.h1}</h1>
					}
				/>
			) : null}

			<TestGrid />
		</header>
	) : null
)

export default Hero
