import React, { useRef } from 'react'
import { Link } from 'gatsby'
import useColorOverHero from 'templates/layout/color-over-hero-hook'
import Cuub from 'svg/cuub'

export default function MobileCuubLogo({ expectHero, heroRef }) {
	const cuubRef = useRef()
	const color = useColorOverHero(expectHero, heroRef, cuubRef, 0.55)

	return (
		<Link
			to="/"
			className={`fixed left-screen-step bottom-screen-step w-1/4 max-w-[7.5rem] lg:hidden ${
				color === 'white' ? 'block text-white' : 'hidden'
			} z-10 transition-colors duration-300`}
			ref={cuubRef}
		>
			<Cuub />
		</Link>
	)
}
