import React from 'react'
import { Link } from 'gatsby'

const QuoteButton = ({ color }) => (
	<Link
		to="https://research.typeform.com/to/d5SVcDHm"
		className={`group relative block h-full w-full overflow-hidden uppercase tracking-px text-white ${
			color === 'black' ? 'bg-black' : ''
		} transition-colors duration-300`}
	>
		<div
			key="first"
			className="pointer-events-none absolute top-0 left-full h-full w-full bg-accent transition-transform duration-300 ease-in group-hover:-translate-x-full"
		/>
		<div
			key="second"
			className="pointer-events-none absolute top-0 left-0 flex h-full w-full items-center justify-between pl-4"
		>
			<div className="flex-initial whitespace-nowrap font-semibold leading-tight transition-colors duration-300 group-hover:text-white">
				Get a Quote
			</div>
			<div className="mr-4 flex h-full w-8 items-center justify-end">
				<i
					className={`block h-3 w-3 bg-accent transition-colors duration-100 group-hover:bg-accent`}
				/>
			</div>
		</div>
	</Link>
)

export default QuoteButton
