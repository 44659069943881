import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from 'utils/link-resolver'
import NormalizeVacancyData from 'utils/normalize-vacancy-data'
import VacancyTpl from 'templates/careers/vacancy'

const VacancyPage = ({ data }) => {
	const vacancy = data.prismicVacancy.data
	const jobLinks = []

	if (vacancy?.work_ua_link?.url) {
		jobLinks.push({
			url: vacancy.work_ua_link?.url,
			text: 'Apply through Work.ua',
		})
	}

	if (vacancy?.robota_ua_link?.url) {
		jobLinks.push({
			url: vacancy.robota_ua_link?.url,
			text: 'Apply through Robota.ua',
		})
	}

	const topFields = []
	if (vacancy?.location) {
		topFields.push({
			heading: 'Location',
			text: vacancy.location,
		})
	}
	if (vacancy?.experience_years) {
		topFields.push({
			heading: 'Experience',
			text: vacancy.experience_years,
		})
	}

	vacancy.jobLinks = jobLinks
	vacancy.topFields = topFields

	const content = {
		vacancy,
		otherVacancies: {
			list: data.allPrismicVacancy.nodes.slice(0, 3).map(NormalizeVacancyData),
			totalCount: data.allPrismicVacancy.nodes.length,
		},
	}

	return <VacancyTpl {...content} />
}

export const query = graphql`
	query prismicSingleVacancy($uid: String) {
		prismicVacancy(uid: { eq: $uid }) {
			_previewable
			uid
			data {
				description {
					richText
				}
				experience_years
				is_active
				location
				title
				work_ua_link {
					url
				}
				robota_ua_link {
					url
				}
				hurma_vacancy_id
			}
		}
		allPrismicVacancy(
			filter: { data: { is_active: { eq: true } }, uid: { ne: $uid } }
			sort: { order: ASC, fields: data___priority }
		) {
			nodes {
				uid
				data {
					location
					title
				}
			}
		}
	}
`

export default withPrismicPreview(VacancyPage, [
	{
		repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
		linkResolver,
	},
])
