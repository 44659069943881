import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import UppyUploader from 'templates/brief/uppy-uploader'
import { DesignRow, Heading4, RegularText } from 'components/design'

export default function ApplicationForm({ jobTitle, vacancyId }) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [submissionError, setSubmissionError] = useState(false)
	const [submissionSuccess, setSubmissionSuccess] = useState(false)
	const [attachmentError, setAttachmentError] = useState(false)
	const uploadFolderName = useRef(new Date().toISOString())
	const [isUploadInProgress, setIsUploadInProgress] = useState(false)
	const uploadsRegistry = useRef({})
	const registerUploads = (fieldName, uploads) => {
		uploadsRegistry.current[fieldName] = uploads

		console.log('REGISTERED', uploadsRegistry.current)
	}

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			name: '',
			email: '',
			cv_link: '',
			portfolio_link: '',
			message: '',
		},
	})

	const rules = {
		name: {
			required: 'Please introduce yourself',
			minLength: {
				value: 3,
				message: 'Name cannot be shorter than 3 characters',
			},
		},
		email: {
			required: 'The email is required',
			pattern: {
				value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
				message: 'Please provide a valid email address',
			},
		},

		message: {
			required: 'The cover letter is required',
		},
	}

	const validateCvPortfolio = () => {
		return Boolean(
			watch('cv_link') ||
				watch('portfolio_link') ||
				uploadsRegistry.current.hasOwnProperty('Uploads')
		)
	}

	const submitApplicationForm = (values) => {
		if (!validateCvPortfolio()) {
			setAttachmentError(true)
			return
		}

		// const endpoint = 'http://localhost:2008/forms/job'
		const endpoint =
			'https://europe-west3-cuub-website.cloudfunctions.net/zCrmConnector/forms/job'

		setIsSubmitting(true)

		// console.log(values)
		// setIsSubmitting(false)

		fetch(endpoint, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				jobTitle,
				vacancyId,
				...values,
				uploadsList: uploadsRegistry.current,
			}),
		})
			.then((response) => {
				if (response.ok) {
					window.dataLayer &&
						window.dataLayer.push({ event: 'job_application.submit' })
					setSubmissionSuccess(true)
				} else {
					setIsSubmitting(false)
					setSubmissionError(true)
				}
			})
			.catch((error) => {
				console.error('Error:', error)
				setIsSubmitting(false)
				setSubmissionError(true)
			})
	}

	return (
		<>
			<form
				onSubmit={handleSubmit(submitApplicationForm)}
				className={`transition-500 transition-all ${
					submissionSuccess ? 'hidden' : ''
				}`}
			>
				<DesignRow
					className=""
					leftColumn={
						<>
							<input
								className={`mt-10 w-full rounded-none border-none focus:outline focus:outline-1 focus:ring-black ${
									errors?.name ? 'outline outline-1 outline-accent' : ''
								} bg-black/5 p-4`}
								type="text"
								placeholder="Your name"
								{...register('name', rules.name)}
							/>
							<p className="ml-4 mt-px text-sm italic text-accent">
								{errors?.name ? errors.name.message : ''}
							</p>

							<input
								className={`mt-8 w-full rounded-none border-none focus:outline focus:outline-1 focus:ring-black ${
									errors?.email
										? 'outline outline-1 outline-accent'
										: ''
								} bg-black/5 p-4`}
								type="email"
								placeholder="Your email"
								{...register('email', rules.email)}
							/>
							<p className="ml-4 mt-px text-sm italic text-accent">
								{errors?.email ? errors.email.message : ''}
							</p>

							<input
								className="mt-8 w-full rounded-none border-none bg-black/5 p-4 focus:outline focus:outline-1 focus:ring-black"
								type="text"
								placeholder="Link to your CV"
								{...register('cv_link')}
							/>

							<input
								className="mt-8 mb-10 w-full rounded-none border-none bg-black/5 p-4 focus:outline focus:outline-1 focus:ring-black"
								type="text"
								placeholder="Link to your portfolio"
								{...register('portfolio_link')}
							/>
						</>
					}
					rightColumn={
						<>
							<textarea
								className={`-mt-6 w-full rounded-none border-none focus:outline focus:outline-1 focus:ring-black md:mt-10 ${
									errors?.name ? 'outline outline-1 outline-accent' : ''
								} bg-black/5 p-4 `}
								rows="12"
								placeholder="Cover letter"
								{...register('message', rules.message)}
							/>
							<p className="ml-4 mb-10 text-sm italic text-accent">
								{errors?.message ? errors.message.message : ''}
							</p>
						</>
					}
				/>

				<DesignRow
					className=""
					leftColumn={
						<>
							<RegularText>
								Alternatively, attach CV and portfolio:
							</RegularText>
							<UppyUploader
								folderName={uploadFolderName.current}
								fieldName={'Uploads'}
								notifyUploadStatus={setIsUploadInProgress}
								notifyUploadList={registerUploads}
							/>
							{attachmentError ? (
								<p className="mt-4 text-sm italic text-accent">
									Please attach CV or portfolio as links or files.
								</p>
							) : null}
						</>
					}
					rightColumn={
						<>
							<button
								className={`cta-black cta-black-wide relative flex w-full items-center bg-black px-6 py-4 text-xs font-semibold uppercase leading-normal tracking-px text-white disabled:cursor-not-allowed disabled:bg-opacity-50 xl:items-baseline xl:px-10 xl:py-5 xl:text-sm xl:leading-[2.5rem]`}
								type="submit"
								disabled={
									isUploadInProgress ||
									isSubmitting ||
									Object.keys(errors).length > 0
								}
							>
								Send message
							</button>

							<p
								className={`mt-8 text-lg font-bold italic text-accent ${
									submissionError ? 'block' : 'hidden'
								}`}
							>
								Sorry for the technical difficulties. Please contact us
								via hello@cuubstudio.com.
							</p>
						</>
					}
				/>
			</form>

			<DesignRow
				className={`transition-500 mt-12 transition-all ${
					submissionSuccess ? '' : 'hidden'
				}`}
				columnToColumn={
					<Heading4>
						Thanks! Your application has been saved. We'll be in touch
						shortly.
					</Heading4>
				}
			/>
		</>
	)
}
