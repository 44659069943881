import React from 'react'

const OutboudLink = ({ url, children }) => (
	<a
		className="accent-hover flex w-full justify-between"
		href={url}
		target="_blank"
		rel="noreferrer"
	>
		<span>{children}</span>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<g fill="none" fillRule="evenodd">
				<path
					d="M0 0H24V24H0z"
					transform="translate(-616.000000, -1454.000000) translate(360.000000, 1454.000000) translate(256.000000, 0.000000)"
				/>
				<path
					fill="#FF4438"
					d="M16.933 16.667L15.733 16.667 15.734 9.116 7.89 16.958 7.042 16.11 14.885 8.267 7.333 8.267 7.333 7.067 16.8 7.2z"
					transform="translate(-616.000000, -1454.000000) translate(360.000000, 1454.000000) translate(256.000000, 0.000000)"
				/>
			</g>
		</svg>
	</a>
)

export default OutboudLink
