import React from 'react'
import { Link } from 'gatsby'
import { DesignRow, RegularText, Heading3 } from 'components/design'

/* Corresponding CSS at _careers.css */
const VacanciesList = ({ vacancies }) => (
	<DesignRow
		className="mb-8"
		gutterToGutter={
			<div className="flex flex-wrap">
				{vacancies.map(({ url, location, title }) => (
					<Link
						to={url}
						key={url}
						className="vacancy-box group flex aspect-square flex-col justify-between bg-black bg-opacity-10 transition-colors duration-300 hover:bg-opacity-20"
					>
						<RegularText>{location}</RegularText>
						<Heading3 className="group-hover:underline">
							{title} {Array(3 - (vacancies.length % 3))}
						</Heading3>
					</Link>
				))}
				{[...Array(3 - (vacancies.length % 3)).keys()].map((key) => (
					<div className="vacancy-box" key={key} />
				))}
			</div>
		}
	/>
)

export default VacanciesList
