export const linkResolver = (doc) => {
	const typeToLink = {
		home_page: '/',
		single_about: '/about/',
		single_contacts: '/contacts/',
		testimonials: '/',
		'404_page': '/404/',
		blog_post: `/blog/${doc.uid}/`,
		project: `/projects/${doc.uid}/`,
		service_list: '/services/',
		service_page: `/services/${doc.uid}/`,
		service_page_v2: `/services/${doc.uid}/`,
		vacancy: `/careers/${doc.uid}/`,
		faq_page: `/faq/`,
		art_stand: `/art/`,
		portfolio_page:
			doc.uid === 'all'
				? '/protected/portfolio/'
				: `/protected/portfolio/category/${doc.uid}/`,
	}

	if (typeToLink.hasOwnProperty(doc.type)) {
		return typeToLink[doc.type]
	}

	return '/'
}
